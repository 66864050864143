import { IconWrapper } from '@grace-studio/graceful-next/components';
import classNames from 'classnames';

type ArrowDirection = 'right' | 'left' | 'up' | 'down' | 'away';
type ArrowType = {
  direction?: ArrowDirection;
  className?: string;
  width?: number;
  height?: number;
};

const IconArrow = ({
  direction = 'right',
  className,
  width,
  height,
}: ArrowType) => {
  const directionClass: Record<typeof direction, string> = {
    right: '',
    left: 'rotate-180',
    up: '-rotate-90',
    down: 'rotate-90',
    away: '-rotate-45',
  };

  return (
    <IconWrapper
      className={classNames(
        className,
        directionClass[direction],
        'origin-center'
      )}
      width={width ?? 24}
      height={height ?? 24}
    >
      <path
        d="M14.7641 4L22 12L14.7641 20L13.2012 18.5481L18.2663 13.037L2 13.037L2 10.963L18.2663 10.963L13.2012 5.45185L14.7641 4Z"
        fill="currentColor"
      />
    </IconWrapper>
  );
};
export default IconArrow;
