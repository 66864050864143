import Button from '@/components/atoms/Button';
import NextWorkout from '@/components/atoms/NextWorkout';
import IconArrow from '@/components/icons/IconArrow';
import { MenuProps } from '@/types/menu';
import { Drawer } from '@grace-studio/graceful-next/components';
import { AnimatePresence, motion } from 'framer-motion';

type DrawerProps = {
  isOpen: boolean;
  menu?: MenuProps;
};

const MenuDrawer = ({ isOpen, menu }: DrawerProps) => {
  const transition = {
    delay: 0.25,
    duration: 0.35,
    opacity: { duration: 0.5 },
  };
  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: transition,
    },
    hidden: (custom: number) => ({
      y: custom === 1 ? 80 : -80,
      opacity: 0,
    }),
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <Drawer isOpen={isOpen} className=" top-0 lg:hidden z-30">
          <motion.div
            className="bg-accent-wine flex flex-col px-3 pt-8 pb-12 fixed justify-between h-full w-screen text-BW-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div variants={variants} initial="hidden" animate="visible">
              <NextWorkout {...menu?.nextWorkout} />
            </motion.div>

            <motion.div
              className="flex flex-col gap-10"
              variants={variants}
              initial="hidden"
              animate="visible"
              custom={1}
            >
              <ul className="flex flex-col text-xl text-BW-white gap-3">
                {menu?.items.map((item) => (
                  <li key={item._key}>
                    <a
                      className="flex uppercase justify-between"
                      href={item.slug}
                    >
                      {item.title} <IconArrow />
                    </a>
                  </li>
                ))}
              </ul>
              <div className="flex gap-2">
                {menu?.socials.map((social) => (
                  <Button
                    key={social._key}
                    style="light"
                    type="secondary"
                    href={social.url}
                    {...{ target: '_blank' }}
                  >
                    {social.text}
                  </Button>
                ))}
              </div>
            </motion.div>
          </motion.div>
        </Drawer>
      )}
    </AnimatePresence>
  );
};
export default MenuDrawer;
