import { IconWrapper } from '@grace-studio/graceful-next/components';
import classNames from 'classnames';

type LogoProps = {
  className?: string;
};
const Logo = ({ className }: LogoProps) => (
  <IconWrapper className={classNames(className, 'h-16')} width={39} height={64}>
    <g id="Logo">
      <path
        id="Vector"
        d="M16.5549 60.5809C20.4937 60.5851 24.4062 60.59 28.3751 60.5961V64.0001H10.4668L22.4038 43.3984H10.4882V39.9927H27.4576C27.8099 39.9927 28.1622 39.9914 28.5108 39.9866C24.5451 46.8639 20.5322 53.7127 16.5543 60.5809H16.5549Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M19.4683 24.9877C12.5527 24.9877 6.92578 19.3831 6.92578 12.4935C6.92578 5.604 12.5521 0 19.4683 0C26.3845 0 32.0102 5.60461 32.0102 12.4942C32.0102 19.3837 26.3839 24.9883 19.4683 24.9883V24.9877ZM19.4683 3.42954C14.4512 3.42954 10.3692 7.49576 10.3692 12.4935C10.3692 17.4913 14.4512 21.5575 19.4683 21.5575C24.4854 21.5575 28.5674 17.4913 28.5674 12.4935C28.5674 7.49576 24.4854 3.42954 19.4683 3.42954Z"
        fill="currentColor"
      />
      <path
        id="Vector_3"
        d="M39 31.0242H0V32.1812H39V31.0242Z"
        fill="currentColor"
      />
    </g>
  </IconWrapper>
);
export default Logo;
