import ContentWrapper from '@/components/ContentWrapper';
import RollingBanner from '@/components/RollingBanner';
import BaseLink from '@/components/atoms/BaseLink';
import Button from '@/components/atoms/Button';
import Logo from '@/components/icons/Logo';
import useWindowSize from '@/hooks/useWindowSize';
import { MenuProps } from '@/types/menu';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

type FooterProps = {
  menu: MenuProps;
  banner: {
    text: string;
  };
};
const Footer = ({ menu, banner }: FooterProps) => {
  const { mobileView } = useWindowSize();

  return (
    <footer className="overflow-hidden pb-10 bg-[var(--backgroundColor)] relative z-10">
      <RollingBanner {...banner} />
      <ContentWrapper
        marginAfter="none"
        className="grid-base items-baseline gap-20"
      >
        <div className="flex col-span-full px-6 gap-2 justify-center lg:contents flex-wrap">
          <ul className="contents lg:flex gap-6 col-span-5 row-start-1 col-end-13 justify-self-end">
            {menu?.items?.map((item) => (
              <li key={item._key}>
                <ConditionalButton
                  condition={mobileView}
                  href={item.slug === 'start' ? '/' : item.slug}
                >
                  {item.title}
                </ConditionalButton>
              </li>
            ))}
          </ul>
          <ul className="contents lg:flex gap-6">
            {menu?.socials?.map((social) => (
              <li key={social._key}>
                <ConditionalButton
                  condition={mobileView}
                  href={social.url ?? ''}
                >
                  {social.text}
                </ConditionalButton>
              </li>
            ))}
          </ul>
        </div>
        <div className="max-lg:contents text-sm col-span-2 col-start-4">
          <span className="col-span-2">OZ—STUDIO </span>
          <span className="col-end-7 justify-self-end">2023©</span>
        </div>
        <Link
          href="/"
          className="row-start-2 col-span-2 col-start-3 lg:row-start-1 lg:col-start-6 justify-self-center"
        >
          <Logo />
        </Link>
      </ContentWrapper>
    </footer>
  );
};
export default Footer;

type ConditionalButtonProps = PropsWithChildren<{
  href: string;
  condition: boolean;
}>;

const ConditionalButton = ({
  condition,
  href,
  children,
}: ConditionalButtonProps) => {
  return condition ? (
    <Button href={href} type="secondary">
      {children}
    </Button>
  ) : (
    <BaseLink showVisited={false} href={href}>
      {children}
    </BaseLink>
  );
};
