import { ClientConfig, SanityClient, createClient } from 'next-sanity';
import ImageUrlBuilder from '@sanity/image-url';
import type { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { DataFactory } from '@/factories/dataFactory';

const config: ClientConfig = {
  projectId: 'lzssvhs6',
  dataset: 'production',
  apiVersion: '2023-06-26',
  useCdn: false,
};

const client = createClient(config);

const clientType = (previewToken?: string): SanityClient =>
  previewToken
    ? client.withConfig({
        token: previewToken,
        useCdn: false,
        ignoreBrowserTokenWarning: true,
        perspective: 'previewDrafts',
      })
    : client;

type ClientArgs = Parameters<typeof client.fetch>;

export const sanityFetch = <T extends any[]>(
  query: ClientArgs[0],
  token?: string,
  params?: ClientArgs[1]
): Promise<T> =>
  clientType(token)
    .fetch(query, params)
    .then((res) => DataFactory.recursiveAddKeysToArray(res));

const builder = ImageUrlBuilder(client);
export const imageUrl = (source: SanityImageObject) => {
  return builder.image(source);
};
export const videoUrl = (video: any) => {
  const [, id, format] = video.asset._ref.split('-');

  return `https://cdn.sanity.io/files/${config.projectId}/${config.dataset}/${id}.${format}`;
};
