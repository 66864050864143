import Button from '@/components/atoms/Button';
import NavButton from '@/components/atoms/NavButton';
import NextWorkout from '@/components/atoms/NextWorkout';
import Logo from '@/components/icons/Logo';
import { MenuProps } from '@/types/menu';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import MenuDrawer from './MenuDrawer';
import BaseLink from '@/components/atoms/BaseLink';
import { useLinkUrl } from '@/hooks/useLinkUrl';
import classNames from 'classnames';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import { PageTypes } from '@/types/generic';

type HeaderType = {
  pageType: PageTypes;
  menu?: MenuProps;
};
const Header = ({ menu, pageType }: HeaderType) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { linkUrl } = useLinkUrl(menu?.contactButton);
  const [showFilter, setShowFilter] = useState<boolean>();
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (pageType !== 'editorial') {
      setShowFilter(
        latest >=
          (pageType === 'start'
            ? window.innerHeight - 100
            : window.innerHeight - 200)
      );
    }
  });

  useEffect(() => {
    if (pageType === 'editorial') {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }, [pageType]);

  return (
    <header
      className={classNames(
        'fixed w-full z-50 py-4 lg:py-8 text-BW-white ',
        !isOpen && showFilter && 'mix-blend-difference'
      )}
    >
      <div className=" grid-base w-full mx-auto items-center px-5 lg:px-10 ">
        <NextWorkout
          className="max-lg:hidden lg:col-span-3"
          {...menu?.nextWorkout}
        />
        <Link
          href="/"
          className="col-span-2 col-start-3 lg:col-start-6 lg:row-start-1 justify-self-center"
        >
          <Logo />
        </Link>

        <nav className="col-span-4 justify-self-end uppercase text-sm max-lg:hidden">
          <ul className="flex gap-6 flex-wrap">
            {menu?.items.map((item) => (
              <BaseLink
                showVisited={false}
                style="light"
                key={item._key}
                href={item.slug === 'start' ? '/' : item.slug}
              >
                {item.title}
              </BaseLink>
            ))}
          </ul>
        </nav>
        {!isOpen && menu?.contactButton && (
          <Button
            className="col-start-1 col-span-2 row-start-1 lg:justify-self-end lg:col-start-12"
            href={linkUrl}
            type="secondary"
            style="light"
            size="small"
          >
            {menu.contactButton.text}
          </Button>
        )}
        <NavButton
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          className="col-span-2 justify-self-end lg:hidden"
        />
      </div>
      <MenuDrawer isOpen={isOpen} menu={menu} />
    </header>
  );
};
export default Header;
