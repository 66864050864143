import classNames from 'classnames';

type NavButtonProps = {
  isOpen?: boolean;
  className?: string;
  onClick?: () => void;
};
const NavButton = ({ className, isOpen, onClick }: NavButtonProps) => {
  const toggleLineClass = 'h-[1px] w-full border-b transition-all duration-300';

  return (
    <button
      className={classNames(
        'border text-sm rounded-full w-fit py-[8px] px-[10px] flex uppercase items-center gap-2',
        className
      )}
      onClick={onClick}
    >
      <div className="inline-flex flex-col w-6 gap-[2px]">
        <span
          className={classNames(
            toggleLineClass,
            isOpen ? 'translate-y-[3px]' : ''
          )}
        ></span>
        <span className={toggleLineClass}></span>
        <span
          className={classNames(
            toggleLineClass,
            isOpen ? '-translate-y-[3px]' : ''
          )}
        ></span>
      </div>
      <span>{isOpen ? 'Close' : 'Menu'}</span>
    </button>
  );
};

export default NavButton;
