'use client';
import classNames from 'classnames';
import { motion } from 'framer-motion';

type RollingBannerProps = {
  text: string;
  className?: string;
};

const RollingBanner = (props: RollingBannerProps) => {
  const { text = '—oz studio—by emelie orozs', className } = props;

  const animation = {
    initial: { x: '0' },
    animate: { x: '-100%' },
    transition: {
      duration: 18,
      repeat: Infinity,
      ease: 'linear',
      type: 'tween',
    },
  };

  return (
    <div
      className={classNames(
        'overflow-hidden w-screen h-[218px] lg:h-[288px]',
        className
      )}
    >
      <div className="min-w-full h-full uppercase text-huge items-center flex flex-nowrap whitespace-nowrap">
        <motion.span {...animation}>{text}</motion.span>
        <motion.span {...animation}>{text}</motion.span>
        <motion.span {...animation}>{text}</motion.span>
      </div>
    </div>
  );
};
export default RollingBanner;
