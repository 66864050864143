'use client';
import { sanityFetch } from '@/api';
import { formatExternalUrl } from '@/helpers/functions';
import { LinkType } from '@/types/generic';
import { useEffect, useState } from 'react';

const formatMailToUrl = ({ url = '', subject }: LinkType) =>
  subject ? url + `?subject=${subject}` : url;

export function useLinkUrl(link: LinkType | undefined) {
  const [linkUrl, setLinkUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!link) {
      return;
    }

    if (link.type === 'custom-url' && link.url) {
      let mailtoUrl = link.url.startsWith('mailto');
      let customUrl = link.url;

      if (mailtoUrl) {
        customUrl = formatMailToUrl(link);
        setLinkUrl(customUrl);
      } else {
        setLinkUrl(formatExternalUrl(customUrl));
      }
    }

    if (link.type === 'link-to-page' && link.page) {
      if (link.page.slug) {
        setLinkUrl(link.page.slug === 'start' ? '/' : link.page.slug);
      } else {
        sanityFetch(`*[_type=='page' && _id == '${link.page?._ref}']`).then(
          (res) => setLinkUrl(res[0].slug.current)
        );
      }
    }
  }, [link]);

  return { linkUrl };
}
