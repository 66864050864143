export const formatExternalUrl = (url: string) =>
  url.includes('.') && !url.startsWith('http') ? 'https://' + url : url || '';

export const formatText = (text: string) => text.replace('-', '—');

export const formatDate = (input: Date, length: 'long' | 'short') => {
  const date = new Date(input);

  const weekday = date.toLocaleString('sv', {
    weekday: 'long',
  });
  const day = date
    .toLocaleString('sv', {
      day: '2-digit',
      month: '2-digit',
    })
    .replace('/', '.');
  const year = date.getFullYear().toString();

  return length === 'long'
    ? `${weekday} ${day}.${year}`
    : `${day}.${year.slice(2, 4)}`;
};

export function isInViewport(element: any) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
