import classNames from 'classnames';
import Link from 'next/link';
import { motion, useCycle } from 'framer-motion';
import { PropsWithChildren } from 'react';

type BaseLinkProps = PropsWithChildren<{
  href: string;
  style?: 'dark' | 'light';
  type?: 'external' | 'internal';
  showVisited?: boolean;
}>;

const BaseLink = ({
  children,
  href,
  style = 'dark',
  showVisited = true,
}: BaseLinkProps) => {
  const [width, cycleWidth] = useCycle('0%', '100%');

  return (
    <Link
      href={href}
      className={classNames(
        style === 'dark' ? 'text-BW-black' : 'text-BW-white',
        showVisited ? 'visited:text-link-visited' : '',
        'flex flex-col',
        'text-sm uppercase max-lg:underline underline-offset-[6px]',
        'disabled:text-link-disabled', //Visited + disabled
        'focus-visible:outline-dashed focus-visible:outline-link-focused focus-visible:outline-2 focus-visible:text-BW-white focus:text-accent-wine' //Focus
      )}
      onMouseEnter={() => cycleWidth(1)}
      onMouseLeave={() => cycleWidth(0)}
      onFocus={() => cycleWidth(1)}
      onBlur={() => cycleWidth(0)}
    >
      {children}
      <motion.span
        initial={false}
        animate={{ width }}
        className={classNames(
          'relative top-1 h-[1px]',
          style === 'dark' ? 'bg-BW-black' : 'bg-BW-white'
        )}
      />
    </Link>
  );
};

export default BaseLink;
