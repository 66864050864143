import classNames from 'classnames';

type NextWorkoutType = {
  date?: Date;
  label?: string;
  className?: string;
};

const NextWorkout = ({
  label = 'Next Workout',
  date = new Date(),
  className,
}: NextWorkoutType) => {
  const dateProps = new Date(date);

  const formattedDate = dateProps
    .toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$2.$1.$3');

  const formattedTime = dateProps
    .toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(':');

  return (
    <div className={classNames('relative whitespace-nowrap', className)}>
      <p
        className={classNames(
          'uppercase inline-flex flex-col lg:flex-row text-sm gap-x-1 text-[inherit]'
        )}
      >
        <span>{label}</span>
        <span>
          {formattedTime[0]}
          <span className="animate-ticker">:</span>
          {formattedTime[1].replace(' ', '')} {formattedDate}
        </span>
      </p>
    </div>
  );
};

export default NextWorkout;
