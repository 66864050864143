import { BaseButton } from '@grace-studio/graceful-next/components';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type ButtonType = PropsWithChildren<{
  type?: 'primary' | 'secondary';
  style?: 'dark' | 'light';
  size?: 'small' | 'big' | 'huge';
  href?: string;
  className?: string;
  onClick?: any;
}>;

const Button = (props: ButtonType) => {
  const {
    type = 'primary',
    style = 'dark',
    size = 'small',
    children,
    className,
    ...other
  } = props;
  const sizeClass: Record<typeof size, string[]> = {
    huge: ['text-lg rounded-3xl w-full'],
    big: ['text-md py-4 lg:py-5'],
    small: ['text-sm py-2'],
  };
  const typeClass: Record<typeof type, string[]> = {
    primary: [
      'hover:bg-transparent',
      style === 'dark'
        ? 'text-BW-white bg-BW-black hover:text-BW-black'
        : 'text-BW-black bg-BW-white hover:text-BW-white',
    ],
    secondary: [
      'bg-transparent',
      style === 'dark'
        ? 'text-BW-black hover:bg-BW-black hover:text-BW-white'
        : 'text-BW-white hover:bg-BW-white hover:text-BW-black',
    ],
  };

  return (
    <>
      <BaseButton
        {...other}
        className={classNames(
          className,
          sizeClass[size],
          typeClass[type],
          size === 'huge'
            ? 'w-full rounded-3xl'
            : 'w-fit h-fit rounded-full px-xs',
          style === 'dark' ? 'border-BW-black' : 'border-BW-white',
          'block text-center border uppercase transition-[background-color] duration-[400ms]',
          'disabled:bg-link-disabled disabled:text-BW-black disabled:pointer-events-none', // Disabled
          'focus-visible:border-dashed focus-visible:border-link-focused focus-visible:outline-none' //Focus
        )}
      >
        {children}
      </BaseButton>
    </>
  );
};

export default Button;
