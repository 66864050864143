import {
  BaseContentWrapper,
  BaseContentWrapperProps,
} from '@grace-studio/graceful-next/components';
import classNames from 'classnames';
import { FC } from 'react';

type ContentWrapperProps = FC<BaseContentWrapperProps>;

const ContentWrapper: ContentWrapperProps = ({
  children,
  className,
  ...props
}) => {
  return (
    <BaseContentWrapper
      contentWidthDefinition={{
        narrow: ['max-w-none'],
        normal: ['max-w-none'],
        wide: ['max-w-none'],
        full: ['max-w-full'],
      }}
      sidePaddingClassName="px-3 md:px-6 lg:px-10"
      marginAfterDefinition={{
        none: ['pb-0'],
        small: ['pb-6'],
        medium: ['pb-20'],
        large: ['pb-40'],
      }}
      {...props}
      className={classNames(className, 'bg-[var(--backgroundColor)]')}
    >
      {children}
    </BaseContentWrapper>
  );
};

export default ContentWrapper;
