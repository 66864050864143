import { useEffect, useMemo, useState } from 'react';

type ScreenTypes = 'desktop' | 'tablet' | 'mobile';
const useWindowSize = () => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [screenType, setScreenType] = useState<ScreenTypes>('mobile');
  const mobileView = useMemo(() => {
    return screenType !== 'desktop';
  }, [screenType]);

  const updateDimensions = (window: Window & typeof globalThis) => {
    if (window !== undefined) {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);

      if (window.innerWidth <= 1024) {
        setScreenType('tablet');
        if (window.innerWidth <= 768) {
          setScreenType('mobile');
        }
      } else {
        setScreenType('desktop');
      }
    }
  };

  useEffect(() => {
    updateDimensions(window);
    window.addEventListener('resize', () => updateDimensions(window));

    return () =>
      window.removeEventListener('resize', () => updateDimensions(window));
  }, []);

  return { height, width, mobileView, screenType };
};
export default useWindowSize;
