import { FC, PropsWithChildren } from 'react';
import Header from '@/compositions/header';
import { MenuProps } from '@/types/menu';
import { PageTypes } from '@/types/generic';
import Footer from '@/compositions/footer';

type BaseLayoutProps = PropsWithChildren<{
  type?: PageTypes;
  menu?: MenuProps;
  footer?: any;
}>;

const Layout: FC<BaseLayoutProps> = (props) => {
  const { children, menu, type, footer } = props;

  console.log({ where: 'Layout', what: 'Menu items from Sanity', menu });

  return (
    <>
      <Header menu={menu} pageType={type ?? 'start'} />
      <main className={type === 'start' ? 'start' : ''}>{children}</main>
      <Footer menu={menu} {...footer} />
    </>
  );
};

export default Layout;
